import { graphql } from 'gatsby';
import Job from '../components/jobs/Job';

export default Job;

export const query = graphql`
  query JobPageQuery($slug: String!, $locale: String!) {
    site {
      siteMetadata {
        siteUrl
        author
        social {
          name
          url
        }
      }
    }
    mdx(fields: { slug: { eq: $slug }, locale: { eq: $locale } }) {
      frontmatter {
        title
        domains
        cities
        contracts
      }
      fields {
        date
      }
      body
    }
  }
`;
