import * as React from 'react';
import { useLocation } from '@reach/router';
import { SocialIcons } from './SocialIcons';
import { SiteMetadataSocialProps } from '../utils/graphql';
import { TFunction } from 'react-i18next';

type SharingBarProps = {
  className: string;
  siteUrl: string;
  socialIcons: SiteMetadataSocialProps[];
  t: TFunction;
  children?: React.ReactNode;
};

const SharingBar: React.FC<SharingBarProps> = ({
  className,
  siteUrl,
  socialIcons,
  t,
  children,
}) => {
  const location = useLocation();

  const pageUrl = location.href || `${siteUrl}${location.pathname}`;

  const [sharingPopin, setSharingPopinState] = React.useState(false);

  React.useEffect(() => {
    if (sharingPopin) {
      setTimeout(() => {
        setSharingPopinState(false);
      }, 2000);
    }
  }, [sharingPopin]);

  const handleSharingClick = () => {
    navigator.clipboard.writeText(pageUrl).then(
      () => setSharingPopinState(true),
      (err) => console.warn('Clipboard write failed', err),
    );
  };

  return (
    <div className={`flex items-center ${className}`}>
      {children}
      <div>
        <button
          className="button m-0 bg-transparent px-4 py-3 font-medium text-black"
          onClick={handleSharingClick}
          type="button"
        >
          {t('render.button.sharing')}
        </button>
        {sharingPopin ? (
          <span className="absolute mt-4 flex -translate-x-1/3 transform items-center bg-reacteev-blue p-3 text-white arrow-color-reacteev-blue arrow-top-6">
            <img
              className="mr-2"
              src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij4KICAgIDxwYXRoIGZpbGw9IiNGQkZCRkIiIGZpbGwtcnVsZT0iZXZlbm9kZCIgZD0iTTEzLjczMyA4LjQ1YTEuMTI0IDEuMTI0IDAgMSAwLTEuMjE1IDEuODk0QTMuNzM2IDMuNzM2IDAgMCAxIDE0LjI1IDEzLjVjMCAxLjAwMi0uMzkgMS45NDMtMS4wOTggMi42NTFsLTQuNSA0LjVBMy43MjYgMy43MjYgMCAwIDEgNiAyMS43NSAzLjc1NCAzLjc1NCAwIDAgMSAyLjI1IDE4YzAtMS4wMDIuMzktMS45NDMgMS4wOTgtMi42NTFsMi4xMzUtMi4xMzVhMS4xMjUgMS4xMjUgMCAxIDAtMS41OS0xLjU5MWwtMi4xMzUgMi4xMzVBNS45NTUgNS45NTUgMCAwIDAgMCAxOGMwIDMuMzA4IDIuNjkyIDYgNiA2IDEuNjAzIDAgMy4xMS0uNjI0IDQuMjQzLTEuNzU4bDQuNS00LjVBNS45NTcgNS45NTcgMCAwIDAgMTYuNSAxMy41YTUuOTc3IDUuOTc3IDAgMCAwLTIuNzY3LTUuMDV6TTE4IDBjLTEuNjAzIDAtMy4xMS42MjQtNC4yNDMgMS43NThsLTQuNSA0LjVBNS45NTYgNS45NTYgMCAwIDAgNy41IDEwLjQ5OWE1Ljk3OCA1Ljk3OCAwIDAgMCAyLjc2NyA1LjA1MmMuMjYzLjE2Ny41NjkuMjEzLjg1Mi4xNWExLjEyIDEuMTIgMCAwIDAgLjg1NS0xLjMzOCAxLjExOCAxLjExOCAwIDAgMC0uNDkyLS43MDYgMy43NCAzLjc0IDAgMCAxLTEuNzMyLTMuMTU4YzAtMS4wMDIuMzktMS45NDMgMS4wOTgtMi42NTFsNC41LTQuNUEzLjcyOSAzLjcyOSAwIDAgMSAxOCAyLjI1IDMuNzU0IDMuNzU0IDAgMCAxIDIxLjc1IDZjMCAxLjAwMS0uMzkgMS45NDMtMS4wOTggMi42NWwtMi4xMzUgMi4xMzVhMS4xMjUgMS4xMjUgMCAxIDAgMS41OTEgMS41OWwyLjEzNS0yLjEzNEE1Ljk1MyA1Ljk1MyAwIDAgMCAyNCA1Ljk5OUMyNCAyLjY5MiAyMS4zMDggMCAxOCAweiIvPgo8L3N2Zz4K"
              alt="Copy Icon"
            />
            {t('render.sharingPopin')}
          </span>
        ) : null}
      </div>
      {(socialIcons || []).map((platform) => {
        const { image: Icon } = SocialIcons[platform.name] || {};
        return (
          <React.Fragment key={platform.url}>
            <a
              href={t(`render.button.sharingLink.${platform.name}.url`, {
                url: encodeURIComponent(pageUrl),
              })}
              title={t(`render.button.sharingLink.${platform.name}.title`)}
              target="_blank"
              rel="noopener noreferrer"
              className="mx-1"
            >
              {Icon ? <Icon className="m-1 inline-block h-7 w-7 align-middle" /> : platform.name}
            </a>
          </React.Fragment>
        );
      })}
    </div>
  );
};

export default SharingBar;
